<template>
<div style="height: 100vh;">
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 4%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <!-- {{os}} -->
    <v-row class="align-center d-flex" style="margin-bottom: -43px; padding-top: 25px;">
        <v-col lg="4" md="7" sm="9" class="mx-auto" style="padding: 0px 0px 0px 0px;">
            <v-card class="" elevation="0" style="background-color: transparent !important;">
                <v-row>
                    <v-col cols="9">
                        <div style="
              text-align: center !important;
              background-color: rgb(168 65 146 / 11%) !important;
              color: #a84192;
              font-weight: 600;
              font-size: 20px;
              border-top-right-radius: 26px;
              border-bottom-right-radius: 26px;
              padding-right: inherit;
              padding-top: 3px;
              padding-bottom: 3px;

            ">
                            <span style="padding-left: 36px;">{{menu_title}}</span>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <v-btn outlined class="mt-1" @click="$router.push('/user_info')" elevation="4" icon small color="primary" style="color:#a84192;">
                            <v-icon color="#a05c92">person</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text style="border-radius: 7px; padding-top: 5px;">
                    <v-container fluid>
                        <v-row dense>
                            <v-col v-for="card in cards_azq" :key="card.title" :cols="12" elevation="5">
                                <v-card @click="$router.push(card.path)" style="
                                    margin: 8px;
                                    font-size: 14px;
                                    box-shadow: 0px 0px 8px rgb(226 226 226 / 64%);
                                    border-radius: 15px;
                                    height: 120px;
                                    background-color: #FEDE00;
                                    ">
                                    <v-container style="text-align: -webkit-center; margin-top: 3px;">
                                        <v-row>
                                            <v-col cols="5" style=" padding: 0px;   text-align: -webkit-right;">
                                                <v-img :src="card.src" width="117px"></v-img>
                                            </v-col>
                                            <v-col style="align-self: center;     text-align: left;">
                                                <v-chip cols="7" class="ma-2" color="#A84192" elevation="5" style="box-shadow: 2px 2px 4px 2px rgba(31, 152, 189, 0.06);
                                                                                                                    border-radius: 15px; color: #A84192;background-color: #F5ECF4 !important;font-size: 18px; line-height: 16pt !important; width: 100%; height:70px; justify-content: center;">
                                                    <span v-html="card.title"></span>
                                                </v-chip>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <!-- <v-img :src="card.src" width="50px"> </v-img> -->
                                    <!-- <v-card-actions style="justify-content: center"> -->

                                    <!-- </v-card-actions> -->
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row class="align-center d-flex pb-0">
        <v-col lg="4" md="7" sm="9" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="background-color: #f0f2f5 !important">
                <v-card-text style="border-radius: 7px; padding-top: 5px;">
                    <v-container fluid style="padding-bottom: 0px !important;">
                        <v-row dense>
                            <v-col v-for="card in cards" :key="card.title" :cols="6">

                                <v-card @click="card.path == 'goHHC' ? goHHC() : $router.push(card.path)" style="
                      margin: 8px;
                      font-size: 14px;
                      box-shadow: 0px 0px 8px rgb(226 226 226 / 64%);
                      border-radius: 15px;
                      height: 150px;

                    ">
                                    <v-container style="text-align: -webkit-center; margin-top: 3px;     padding-bottom: 0px; ">
                                        <v-img :src="card.src" width="70px"></v-img>
                                    </v-container>
                                    <!-- <v-img :src="card.src" width="50px"> </v-img> -->
                                    <v-card-actions style="justify-content: center;padding-top: 0px;">
                                        <v-chip class="ma-2" color="#A84192" pill style="font-size: 16px; line-height: 16pt !important; width: 100%; height:50px; justify-content: center;">
                                            {{ card.title }}
                                        </v-chip>
                                    </v-card-actions>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

        </v-col>

    </v-row>
    <v-row style="position: fixed;bottom: 15px; margin-left: -25px; font-size: 12px;  text-align: center; width: -webkit-fill-available;" class="d-flex justify-center align-end">Powered by &nbsp;Eisai X&nbsp;<a @click="goRoyyak" v-bind:href="url"> Royyak</a></v-row>

</div>
</template>

<script>
export default {
    name: "register-select-type",
    data() {
        return {
            url: '',
            os: '',
            cards: [],
            cards_azq: [],
            user_type: "",
            menu_title: "",
            pdpa_accept: false,
            valid: true,
            name: "",
            email: "",
            select: null,
            checkbox: false,
            loading: false,
            snackbar: false,
            snackbarColor: "default",
        };
    },
    beforeCreate() {

    },
    mounted() {
        // //console.log('home---')
        // //console.log(this.$store.state)
        this.user_type = this.$store.state.login_detail.user_type
        this.getHomeTitle()
        this.getCardMenu()
        // this.$router.go()
        // e.log(window.navigator.userAgent.indexOf("Linux")!= -1)
        // if(window.navigator.userAgent.indexOf("Linux")!= -1){
        //     this.os = 'linux'
        // }
        // else{
        //     this.os = 'window'
        // }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        goRoyyak() {
            window.open("https://royyak.com/");
        },
        goHHC() {
            window.open("https://liff.line.me/1657454775-evNEMK8g");
            // window.open("https://hhcthailand.com/category/health/brain/");
        },
        getHomeTitle() {
            if (this.user_type === 'user_provider') {
                this.menu_title = 'เมนูหลัก รพ./คลินิก '
            }
            if (this.user_type === 'user_osm') {
                this.menu_title = 'เมนูหลัก อสม.'
            }
            if (this.user_type === 'user_patient') {
                this.menu_title = 'เมนูหลักผู้ใช้งาน/ผู้ป่วย'
            }
        },
        getCardMenu() {
            const user_provider_card = [{
                    title: "สรุปข้อมูลผู้ป่วย",
                    src: require("@/assets/img/checkmong/Vectordab.png"),
                    path: '/dashboard_summary'
                },
                {
                    title: "ดูข้อมูลผู้ป่วย",
                    src: require("@/assets/img/checkmong/folderacc.png"),
                    path: "/scan_accessdata",
                },
                {
                    title: "ปฏิทิน\nรายการนัดหมาย",
                    src: require("@/assets/img/checkmong/calendar-checkca.png"),
                    path: "/calendar_provider",
                },
                {
                    title: "แบบบันทึก\nประวัติสุขภาพ",
                    src: require("@/assets/img/checkmong/file-earmark-texts.png"),
                    path: "/scan_add_health_record",
                },
                {
                    title: "Brain Library\n(ห้องสมุดสมอง)",
                    src: require("@/assets/img/checkmong/pending_actionsmeds.png"),
                    path: "goHHC",
                },

                {
                    title: "VDO สอน\nการตรวจประเมิน",
                    src: require("@/assets/img/checkmong/filmvdo.png"),
                    path: "/video_training",
                },
            ]
            const user_osm_card = [{
                    title: "สรุปข้อมูลผู้ป่วย",
                    src: require("@/assets/img/checkmong/Vectordab.png"),
                    path: '/dashboard_summary'
                },
                {
                    title: "ดูข้อมูลผู้ป่วย",
                    src: require("@/assets/img/checkmong/folderacc.png"),
                    path: "/scan_accessdata",
                },
                {
                    title: "แบบบันทึก\nประวัติสุขภาพ",
                    src: require("@/assets/img/checkmong/file-earmark-texts.png"),
                    path: "/scan_add_health_record",
                },
                {
                    title: "Brain Library\n(ห้องสมุดสมอง)",
                    src: require("@/assets/img/checkmong/pending_actionsmeds.png"),
                    path: "goHHC",
                },

                {
                    title: "VDO สอนการ\nตรวจประเมิน",
                    src: require("@/assets/img/checkmong/filmvdo.png"),
                    path: "/video_training",
                },
            ]

            const user_patient_card = [{
                    title: "ปฏิทินนัดพบหมอ",
                    src: require("@/assets/img/checkmong/Vectormet.png"),
                    path: "/calendar_patient",
                },
                {
                    title: "ดูข้อมูลผู้ป่วย",
                    src: require("@/assets/img/checkmong/folderacc.png"),
                    path: "/patient_health_record",
                },
                {
                    title: "นัดหมอ",
                    src: require("@/assets/img/checkmong/person-badgebo.png"),
                    path: "/book_doctor_list",
                },
                // {
                //     title: "เตือนกินยา",
                //     src: require("@/assets/img/checkmong/pending_actionsmed.png"),
                //     path: "/medicine_alert",
                // },
                {
                    title: "Brain Library\n(ห้องสมุดสมอง)",
                    src: require("@/assets/img/checkmong/pending_actionsmeds.png"),
                    path: "goHHC",
                },
                {
                    title: "คิวอาร์ของฉัน",
                    src: require("@/assets/img/checkmong/qr-code.png"),
                    path: "/qr_gen"
                }
            ]
            const azq_card = [{
                title: "ทดสอบภาวะ<br>สมองเสื่อมให้ผู้ป่วย",
                src: require("@/assets/img/checkmong/clipboard-checkazq.png"),
                path: "/scan_azq",
            }]

            const azq_card_patient = [{
                title: "ทดสอบภาวะ<br>สมองเสื่อมให้ผู้ป่วย",
                src: require("@/assets/img/checkmong/clipboard-checkazq.png"),
                path: "/azq",
            }]

            if (this.user_type === 'user_provider') {
                this.cards = user_provider_card
                this.cards_azq = azq_card
            }
            if (this.user_type === 'user_osm') {
                this.cards = user_osm_card
                this.cards_azq = azq_card
            }
            if (this.user_type === 'user_patient') {
                this.cards = user_patient_card
                this.cards_azq = azq_card_patient
            }
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        onFinish(rsp) {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.snackbarColor = rsp === this.expectedOtp ? "success" : "warning";
                this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`;
                this.snackbar = true;
            }, 3500);
        },
    },
};
</script>

<style scoped>
.v-card--link:focus {
    background: white;
}

.v-card--link:focus::before {
    opacity: 0;
}

.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.custom-field {
    border-radius: 6px;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-chip {
    line-height: 14px !important;
    padding: 0 6px !important;
    vertical-align: middle !important;
    white-space: pre-line !important;
    text-align: center !important;
    background: rgba(29, 108, 132, 0.03) !important;
    margin: 0px !important;
}

.v-application .ma-2 {
    margin: 0px !important;
}

.v-card__actions {
    align-items: center;
    display: flex;
    padding: 8px;
    padding-top: 0px;
}

.v-chip.v-size--x-small {
    border-radius: 8px;
    font-size: 10px;
    height: 16px;
    width: 100%;
    justify-content: center;
}

.v-main__wrap {
    padding-bottom: 0px;
}
</style>
